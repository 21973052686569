<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        If you had 0.100 M solutions of each of the following compounds, rank them in terms of the
        resulting pH.
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="items"
        class="mb-1 pl-8"
        style="max-width: 400px"
        prepend-text="Highest pH"
        append-text="Lowest pH"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput';

export default {
  name: 'Question135',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      items: [
        '$\\ce{HBrO2(aq)}$',
        '$\\ce{Ba(OH)2(aq)}$',
        '$\\ce{HBr(aq)}$',
        '$\\ce{NH3(aq)}$',
        '$\\ce{NaOH(aq)}$',
      ],
    };
  },
};
</script>
